.faq {
  padding-bottom: 30px;
  min-height: 75rem;
}

.faqPagination {
  display: inline-flex; /* استخدام flexbox لجعل العنصر مرنًا */
  justify-content: center; /* توسيط النص أفقيًا */
  align-items: center; /* توسيط النص عموديًا */
  margin: 5px; /* إضافة مسافة بين الأزرار */
  width: 40px; /* عرض ثابت */
  height: 40px; /* ارتفاع ثابت */
  font-size: 16px; /* حجم النص */
  font-weight: bold; /* جعل النص غليظًا */
  color: #333; /* لون النص الافتراضي */
  border-radius: 50%; /* جعل العنصر دائريًا */
  cursor: pointer; /* تغيير المؤشر عند التمرير */
  transition: background-color 0.3s, color 0.3s; /* تأثير الانتقال */
  text-align: center; /* تأكيد المحاذاة الأفقية */
  box-sizing: border-box; /* ضمان عدم تأثر الحجم بالحدود */
}


.faq-container {
  padding: 30px;
  font-size: 2.435rem;
}

.faq-container .no-faq,
.faq-container .faq-card {
  background: var(--second-cards-color);
  border-radius: 8px;
  
}

.faq-container .no-faq {
  padding: 30px;
}

.faq-container .faq-card button {
  padding: 10px;
  background: none;
  width: 100%;
  font-weight: bolder;
  align-items: center;
  text-align: start;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.faq-container .faq-card button span.icon {
  padding: 3px;
  border-radius: 5px;
  background: var(--secondary-color);
  display: none;
}

.faq-container .faq-card button span.icon.active {
  display: block;
  font-size: 1rem;
}

.faq-container .faq-card .answer {
  padding: 10px;
  width: 100%;
  font-size: 2.055rem;
  color: var(--sub-text-color);
  margin-bottom: 10px;
}

.no-faq {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.no-faq img {
  width: 30vw;
}

.no-faq p {
  font-size: 2.455rem;
  font-weight: bolder;
  margin-top: 20px;
}
.faqPagination > a {
  color: var(--font-color);
}

/* تنسيق عام للأزرار */





