.category_section {
  margin-bottom: 48px;
  /* padding: 0 !important; */
}

@media (max-width:770px) {
  .category_section {
    margin: 20px auto;
    /* padding: 0 !important; */
  }
}


.product_title_content p {
  font-size: 17px;
  line-height: 32px;
  color: var(--font-color);
  letter-spacing: 2px;
  font-weight: 700 !important;
  margin: 0;
}
@media (max-width: 768px) {/* حمد */
  .category_section_header p {
    font-size: 14px;
    line-height: 30px;
    color: var(--font-color);
    letter-spacing: 2px;
    font-weight: 700 !important;
    margin: 0;
  }
}
.category_section_header span {
  font-size: 12px;

  font-weight: 400 !important;
  letter-spacing: 1px;
  color: var(--secondary-color);
}

@media (max-width: 768px) {/* حمد */
  .category_section_header span {
    font-size: 12px;
    font-weight: 400 !important;
    letter-spacing: 1px;
    color: var(--secondary-color);
  }
}



.category_section .container {
  border-radius: 8px;
}

.category_section_brand {
  margin-bottom: 48px;
}

#allcategories .container .back-button {
  cursor: pointer;
}

.category_section_header {
  margin-bottom: 5px;
  background-color: var(--bs-body-bg);
  padding: 5px;
  margin: 5px;
  border-radius: 5px;
}

[dir="rtl"] .category_section_header .see_category_arrow {
  transform: rotate(180deg);
}

[dir="rtl"] .category_section_header .next-arrow-category {
  order: 0;
}

[dir="rtl"] .category_section_header .prev-arrow-category {
  order: 1;
}

.category-container {
  margin: 0 10px 0 0;
}

/* .slick-prev-category,
.next-arrow-category {
  position: absolute;
  top: 0 !important;
  right: 0 !important;
  left: auto !important;
} */

.category-container .card {
  /* padding: 3em 1.5em; */
  /* border-radius: 8px; */
  text-align: center;
  width: 100%;
  cursor: pointer;
  border: none;
  /* padding: 24px; احمد*/
  padding: 5px;
  border-radius: 10px;
  background-color: transparent !important;
  display: flex;
  align-items: center;
  /* transition: transform 1.2s ease-in-out; */
}

.category-container .card:hover {
  transform: none !important;
  /* border: 1px solid lightgray; */
  /* box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.05); */
  /* border: 1px solid var(--secondary-color); */
}

.card:hover .card-title {
  color: var(--secondary-color);

}

.category-container .card:hover .category_image {
  /* transform: scale(1.07); */
}

.category-container .card .category_image {
  /* width: 120px; */
  border-radius: 100% !important;
  margin: 0 auto;
  /* padding: 8px; */
  border-radius: 7px 7px 0px 0px;
  /* border: 1px solid #ddd; */
  /* height: 150px; */ 
  /* margin-bottom: 15px; */
  /* transition: transform 0.7s ease-in-out; */
  /* border-radius: 100% !important;
  background-color: #fff; */
  padding: 8px;
}

.category-container .card .card-body {}

.category-container .card .card-title {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  display: block;
  margin-top: 10px;
  /* white-space: nowrap; */
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
  height: 42px;
  overflow: hidden;
}

.category-container .category-main-img {
  height: 132px;
  width: 132px;
  display: flex;
}
@media (max-width: 768px) {/* حمد */
  .category-container .category-main-img {
    height: 100px;
    width: 100px;
    display: flex;
  }
}

.category_button:hover {
  /* background: var(--secondary-color); */
  color: var(--secondary-color) !important;
}

.category_button {
  border-radius: 8px;
  font-size: 14px;
  /* padding: 8px 16px; */
  padding: 7px;
  text-decoration: none;
  /* background-color: var(--secondary-color); */
  text-align: center;
  color: var(--font-color) !important;
  margin: 0px 16px;
}

/* #allcategories{
    display: flex;
    flex-direction: column;
} */

#allcategories .cover {
  height: 200px;
}

#allcategories .cover img {
  height: 100%;
  width: 100%;
  filter: brightness(50%);
  z-index: -1;
  background-size: cover;
  background-position: center center;
}

#allcategories .cover .page-heading {
  position: relative;
  bottom: 100px;
  text-align: center;
}

#allcategories .cover .page-heading h5 {
  color: var(--text-field-color);
  font-size: 3rem;
}

#allcategories .cover .page-heading p {
  color: var(--text-field-color);
  font-size: 2rem;
}

#allcategories .cover .page-heading p span {
  color: var(--secondary-color);
}

/* #allcategories .content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px;
} */

#allcategories .content .card {
  /* border-radius: 8px; */
  text-align: center;
  width: 100%;
  cursor: pointer;
  border: none;
  padding: 24px;
  border-radius: 10px;
  background-color: transparent !important;
  display: flex;
  align-items: center;
}

#allcategories.home .content .card {
  border-radius: 8px;
  /* border: none; */
  /* border-top: none; */
  /* padding-top: 10px; */
  margin: 8px;
}

#allcategories .content .card img {
  width: 100%;
  /* height: 26rem; */
  border-radius: 8px 8px 0px 0px;
  aspect-ratio: 1 / 1;
  object-fit: cover;
}

#allcategories .content .card .card-body {
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  /* background-color: var(--secondary-color); */
}

#allcategories .content .card:hover {
  /* outline: var(--secondary-color); */
  /* transform: none !important; */
  /* box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.05); */
  /* border: 1px solid var(--secondary-color); */
  color: var(--secondary-color) !important;
}

#allcategories .content .card .card-body:hover p {
  /* background-color: var(--secondary-color-light);
  color: var(--text-field-color) !important; */
  color: var(--secondary-color) !important;
}

#allcategories .content .card:hover .card-body p {
  /* background-color: var(--secondary-color) !important; */
  color: var(--secondary-color) !important;
}

#allcategories .content .card .card-body p {
  /* font-size: 1.745rem;
    font-weight: bolder;
    color: #fff;
    text-align: center;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
  font-weight: 600;
  font-size: 15px;
  line-height: 26px;
  display: -webkit-box; /* يتيح التحكم في الأسطر */
  -webkit-line-clamp: 2; /* يسمح بحد أقصى لسطرين */
  -webkit-box-orient: vertical; /* تحديد الاتجاه العمودي للنص */
  white-space: normal; /* السماح بالتفاف النص */
  text-align: center;
  overflow: hidden; /* إخفاء النص الزائد */
  text-overflow: ellipsis; /* إضافة الثلاث نقاط عند الاقتصاص */
}
#expandCategory .slick-track {
  margin-left: unset !important;
  margin-right: unset !important;
}

.categoryListPagination{
display: inline-flex; /* استخدام flexbox لجعل العنصر مرنًا */
justify-content: center; /* توسيط النص أفقيًا */
align-items: center; /* توسيط النص عموديًا */
margin: 5px; /* إضافة مسافة بين الأزرار */
width: 40px; /* عرض ثابت */
height: 40px; /* ارتفاع ثابت */
font-size: 16px; /* حجم النص */
font-weight: bold; /* جعل النص غليظًا */
color: #333; /* لون النص الافتراضي */
border-radius: 50%; /* جعل العنصر دائريًا */
cursor: pointer; /* تغيير المؤشر عند التمرير */
transition: background-color 0.3s, color 0.3s; /* تأثير الانتقال */
text-align: center; /* تأكيد المحاذاة الأفقية */
box-sizing: border-box; /* ضمان عدم تأثر الحجم بالحدود */
}

#allcategories .categoryListPagination>a {
  color: var(--font-color);
}

.swiper {
  padding: 0px !important;
}

